<template>
	<div class="container h-full">
		<form class="h-full flex flex-col" @submit.stop.prevent="requestMoreInfo()">
			<p class="mb-8">Welche Zusatzinformationen hättest du gerne über die offene Stelle?</p>
			<HokRadioGroup v-model="moreInfoRequest" class="mb-8">
				<div>
					<HokRadio
						id="moreInfoRequest-wage"
						value="activesourcinginforequest:wage"
						name="moreInfoRequest"
					>
						Genauere Gehaltsangabe
					</HokRadio>
				</div>
				<div>
					<HokRadio
						id="moreInfoRequest-career"
						value="activesourcinginforequest:carrier"
						name="moreInfoRequest"
					>
						Mehr Informationen über meine Aufstiegschancen
					</HokRadio>
				</div>
				<div>
					<HokRadio
						id="moreInfoRequest-education"
						value="activesourcinginforequest:education"
						name="moreInfoRequest"
					>
						Wie sind meine Weiterbildungsmöglichkeiten
					</HokRadio>
				</div>
				<div>
					<HokRadio
						id="moreInfoRequest-other"
						value="activesourcinginforequest:other"
						name="moreInfoRequest"
						style="margin-bottom: 0"
					>
						Andere Information
					</HokRadio>
					<HokTextArea
						v-if="moreInfoRequest === 'activesourcinginforequest:other'"
						id="moreInfoRequest-message"
						v-model="moreInfoRequestMessage"
						type="text"
						name="text"
						question="Frage an das Unternehmen eingeben"
						box-height="small"
						:max-length="150"
						class="pl-6"
					>
						Frage an das Unternehmen eingeben
					</HokTextArea>
				</div>
			</HokRadioGroup>
			<HokButton submit fullwidth="mobile" color="main" class="max-w-xs mx-auto">
				Informationen anfordern
			</HokButton>
		</form>
	</div>
</template>

<script lang="ts">
import HokTextArea from '@hokify/shared-components-nuxt3/lib/components/HokTextArea.vue';
import HokRadioGroup from '@hokify/shared-components-nuxt3/lib/components/HokRadioGroup.vue';
import HokRadio from '@hokify/shared-components-nuxt3/lib/components/HokRadio.vue';
import { insidePages } from '@hokify/shared-components-nuxt3/lib/plugins/insidepage/insidePageService';
import type { MessagingTags } from '@hokify/common';
import { defineComponent } from 'vue';
import { EventBus } from '@hokify/shared-components-nuxt3/lib/eventbus';

export default defineComponent({
	name: 'MoreInfoModal',
	components: { HokTextArea, HokRadioGroup, HokRadio },
	emits: ['request-more-info'],
	data() {
		const moreInfoRequest = 'activesourcinginforequest:wage' as MessagingTags;

		return {
			moreInfoRequest,
			moreInfoRequestMessage: '',
			EventBus
		};
	},
	methods: {
		async requestMoreInfo() {
			switch (this.moreInfoRequest) {
				case 'activesourcinginforequest:carrier':
					this.moreInfoRequestMessage =
						'kann ich mehr über Aufstiegschancen in Ihrem Unternehmen erfahren?';
					break;
				case 'activesourcinginforequest:education':
					this.moreInfoRequestMessage =
						'kann ich mehr über Weiterbildungsmöglichkeiten in Ihrem Unternehmen erfahren?';
					break;
				case 'activesourcinginforequest:wage':
					this.moreInfoRequestMessage =
						'kann ich eine genauere Angabe zu Gehalt/Lohn in dieser Position bekommen?';
					break;
				case 'activesourcinginforequest:other':
				default:
					break;
			}
			if (insidePages?.pages?.length > 0) {
				this.EventBus.$emit('request-more-info', {
					req: this.moreInfoRequest,
					msg: this.moreInfoRequestMessage
				});
				this.$page.goBack();
			} else {
				this.$emit('request-more-info', this.moreInfoRequest, this.moreInfoRequestMessage);
			}
		}
	}
});
</script>
